import i18n from "@/i18n";
import router from "@/router";
import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "@/firebase";
import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

const getDefaultState = () => {
  return {
    status: {},
    user: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async signIn({ commit }, { username, password }) {
    commit("wait");
    try {
      const { user } = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );

      if (user) {
        const id = user.uid;

        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          userData.id = user.uid;
          userData.email = user.email;
          commit("setUser", userData);
          router.push("/");
        }
      }
    } catch (error) {
      var msg = error.code.split("/")[1] || "unknown";
      commit("failure", msg);
    }
  },
  async signOut({ commit, dispatch }) {
    commit("wait");
    try {
      router.push("/login");
      await auth.signOut();
      dispatch("clearAll", null, { root: true });
    } catch (error) {
      commit("failure", error);
    }
  },
  async changePassword({ commit }, password) {
    commit("wait");
    try {
      const updatePassword = httpsCallable(functions, "updatePassword");
      const res = await updatePassword({ password });
      if (!res.data.success) throw res.data.code || "unknown";
      const msg = "password_request_sent";
      commit("success", msg);
    } catch (error) {
      commit("failure", error);
    }
  },
  async initializeState({ commit }, user) {
    if (state.user.id) {
      return;
    }
    try {
      if (user) {
        const id = user.uid;

        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          userData.id = user.uid;
          userData.email = user.email;
          commit("setUser", userData);
        }
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async requestPasswordReset({ commit }, email) {
    commit("wait");
    try {
      await sendPasswordResetEmail(auth, email);
      commit("success", "request");
      router.push("/reset");
    } catch (error) {
      commit("failure", error);
    }
  },
  async resetPassword({ commit }, { code, password }) {
    commit("wait");
    try {
      await confirmPasswordReset(auth, code, password);
      commit("success", "reset");
      router.push("/login");
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateUser({ commit }, { data, user }) {
    commit("wait");
    try {
      const docRef = doc(db, "users", user.id);
      await setDoc(docRef, data, { merge: true });
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        userData.id = user.id;
        userData.email = user.email;
        commit("setUser", userData);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  success(state, msg) {
    if (msg) {
      state.status = {
        success: true,
        msg: msg,
      };
    }
    state.waiting = false;
  },
  failure(state, error) {
    state.status = {
      error: true,
      msg: error,
    };
    state.waiting = false;
  },
  setUser(state, user) {
    state.user = user;
    i18n.global.locale.value = user.locale;
    localStorage.setItem("app-locale", user.locale);
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default user;
