<template>
  <div class="topbar flex justify-content-between align-items-center p-3 pr-0">
    <Button
      class="p-link"
      :style="{
        width: '32px',
        height: '32px',
        verticalAlign: 'middle',
        textAlign: 'center',
        display: 'inline-block',
      }"
      @click="onMenuButtonClick"
    >
      <i class="pi pi-bars" :style="{ fontSize: '32px' }"></i>
    </Button>
    <router-link to="/">
      <!--<button class="p-link">
          <img
            alt="Some Logo"
            src="/layout/images/logo.png"
            class="topbar-logo"
          />
        </button>-->
      <p class="text-white font-bold text-xl">
        {{ t("headers.main") }}
      </p>
    </router-link>

    <div class="topbar-right">
      <Button
        class="p-link"
        id="topbar-menu-button"
        @click="onTopbarMenuButtonClick"
      >
        <i class="pi pi-user" :style="{ fontSize: '2rem' }"></i>
      </Button>
      <ul :class="topbarItemsClass">
        <li
          v-if="profileMode === 'top' || horizontal"
          :class="[
            'profile-item',
            { 'active-top-menu': activeTopbarItem === 'profile' },
          ]"
          @click="
            $emit('topbar-item-click', {
              originalEvent: $event,
              item: 'profile',
            })
          "
        >
          <button class="p-link">
            <img
              alt="User"
              class="profile-image"
              src="/layout/images/login/icon-login.svg"
            />
            <div>
              <span class="topbar-item-name">{{ user.name }}</span>
              <span class="topbar-item-role">{{ t("labels.admin") }}</span>
            </div>
          </button>

          <transition name="layout-submenu-container">
            <ul
              class="layout-menu fadeInDown"
              v-show="activeTopbarItem === 'profile'"
            >
              <li v-for="item in menuItems" :key="item.label" role="menuitem">
                <button class="p-link topbar-item-button" @click="item.click">
                  <i :class="item.icon"></i>
                  <span>{{ t("nav." + item.label) }}</span>
                </button>
              </li>
            </ul>
          </transition>
        </li>

        <li
          :class="[{ 'active-top-menu': activeTopbarItem === 'Language' }]"
          @click="
            $emit('topbar-item-click', {
              originalEvent: $event,
              item: 'language',
            })
          "
        >
          <button class="p-link">
            <i class="topbar-icon pi pi-fw pi-globe"></i>
            <span class="topbar-item-name">{{ t("nav.language") }}</span>
          </button>
          <transition name="layout-submenu-container">
            <ul
              class="layout-menu fadeInDown"
              v-show="activeTopbarItem === 'language'"
            >
              <li
                v-for="(language, index) in languages"
                :key="index"
                role="menuitem"
              >
                <a href="javascript:;" @click="setLanguage(language.value)">
                  <span>{{ language.name }}</span>
                </a>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import languageList from "./i18n/list";

export default {
  props: {
    topbarMenuActive: Boolean,
    profileMode: String,
    horizontal: Boolean,
    activeTopbarItem: String,
  },
  setup(props, { emit }) {
    const onMenuButtonClick = (event) => {
      emit("menubutton-click", event);
    };

    const onTopbarMenuButtonClick = (event) => {
      emit("topbar-menubutton-click", event);
    };

    const topbarItemsClass = computed(() => {
      return [
        "topbar-items fadeInDown",
        {
          "topbar-items-visible": props.topbarMenuActive,
        },
      ];
    });

    const store = useStore();

    const menuItems = [
      {
        label: "logout",
        click: () => store.dispatch("user/signOut"),
        icon: "pi pi-fw pi-sign-out",
      },
    ];

    const { t } = useI18n();

    const languages = languageList;

    const user = computed(() => store.getters["user/getUser"]);

    const setLanguage = (lang) => {
      store.dispatch("user/updateUser", {
        data: { locale: lang },
        user: user.value,
      });
    };

    return {
      onMenuButtonClick,
      onTopbarMenuButtonClick,
      topbarItemsClass,
      menuItems,
      languages,
      setLanguage,
      user,
      t,
    };
  },
};
</script>
