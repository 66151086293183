<template>
  <form @submit.prevent="handleSubmit(!v$.$invalid)" class="grid text-primary">
    <div class="col-3">
      <img src="/layout/images/login/icon-login.svg" alt="avalon-vue" />
    </div>
    <div class="col-9">
      <h2>{{ t("headers.login") }}</h2>
      <span>{{ t("headers.login_sub") }}</span>
    </div>
    <div class="col-12 p-fluid">
      <label>{{ t("labels.email") }}</label>
      <div class="mt-1">
        <InputText
          :placeholder="t('placeholders.email')"
          v-model="v$.username.$model"
          :class="{
            'p-invalid': v$.username.$invalid && submitted,
          }"
        />
        <small v-if="v$.username.$invalid && submitted" class="p-error">{{
          t("form_errors.email")
        }}</small>
      </div>
    </div>
    <div class="col-12 p-fluid">
      <label>{{ t("labels.password") }}</label>
      <div class="mt-1">
        <Password
          :placeholder="t('placeholders.password')"
          :feedback="false"
          toggleMask
          v-model="v$.password.$model"
          :class="{
            'p-invalid': v$.password.$invalid && submitted,
          }"
        />
        <small v-if="v$.password.$invalid && submitted" class="p-error">{{
          t("form_errors.password")
        }}</small>
      </div>
    </div>
    <div class="col-12 flex flex-column">
      <Button :label="t('buttons.login')" type="submit" />
      <Button
        :label="t('buttons.forgot')"
        class="p-button-link mt-3"
        @click="emit('forgot')"
      />
    </div>
  </form>
</template>

<script>
import { reactive, ref } from "vue";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup(props, { emit }) {
    const loginData = reactive({
      username: "",
      password: "",
    });

    const rules = {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    };

    const submitted = ref(false);

    const v$ = useVuelidate(rules, loginData);

    const store = useStore();

    const handleSubmit = (isFormValid) => {
      submitted.value = true;

      if (!isFormValid) return;

      store.dispatch("user/signIn", loginData);
    };

    const { t } = useI18n();

    return {
      emit,
      v$,
      submitted,
      handleSubmit,
      t,
    };
  },
};
</script>
