<template>
  <form
    @submit.prevent="handleSubmit(!v$.$invalid)"
    class="grid p-fluid text-primary"
  >
    <div class="col-12">
      <label>{{ t("labels.email") }}</label>
      <div class="mt-1">
        <InputText
          :placeholder="t('placeholders.email')"
          v-model="v$.username.$model"
          :class="{
            'p-invalid': v$.username.$invalid && submitted,
          }"
        />
        <small v-if="v$.username.$invalid && submitted" class="p-error">{{
          t("form_errors.email")
        }}</small>
      </div>
    </div>
    <div class="col-12 md:col-6" />
    <div class="col-12 md:col-6">
      <Button
        :label="
          route.path.includes('profile') ? t('buttons.save') : t('buttons.send')
        "
        :loading="waiting"
        type="submit"
      />
    </div>
  </form>
</template>

<script>
import { computed, ref } from "vue";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default {
  setup(props, { emit }) {
    const username = ref("");

    const rules = {
      username: {
        required,
        email,
      },
    };

    const submitted = ref(false);

    const v$ = useVuelidate(rules, { username });

    const store = useStore();

    const route = useRoute();

    const handleSubmit = (isFormValid) => {
      submitted.value = true;

      if (!isFormValid) return;

      if (route.path == "/login") {
        store.dispatch("user/requestPasswordReset", username.value);
      } else {
        emit("save", username.value);
      }
    };

    const { t } = useI18n();

    return {
      waiting: computed(() => store.state.user.waiting),
      v$,
      route,
      submitted,
      handleSubmit,
      t,
    };
  },
};
</script>
