<template>
  <div
    :class="containerClass"
    @click="onDocumentClick"
    :style="{
      backgroundImage: 'url(/layout/images/bg.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }"
  >
    <div class="layout-wrapper-content">
      <!--<AppTopBar
        :topbarMenuActive="topbarMenuActive"
        :horizontal="layoutMode === 'horizontal'"
        :activeTopbarItem="activeTopbarItem"
        @menubutton-click="onMenuButtonClick"
        @topbar-menubutton-click="onTopbarMenuButtonClick"
        @topbar-item-click="onTopbarItemClick"
      ></AppTopBar>-->
      <AppHeader
        :topbarMenuActive="topbarMenuActive"
        :profileMode="profileMode"
        :horizontal="layoutMode === 'horizontal'"
        :activeTopbarItem="activeTopbarItem"
        @menubutton-click="onMenuButtonClick"
        @topbar-menubutton-click="onTopbarMenuButtonClick"
        @topbar-item-click="onTopbarItemClick"
      />
      <transition name="layout-menu-container">
        <div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
          <div class="menu-scroll-content">
            <!-- <AppInlineProfile
              v-if="profileMode === 'inline' && layoutMode !== 'horizontal'"
              @profile-click="onProfileClick"
              :expanded="profileExpanded"
            ></AppInlineProfile> -->

            <AppMenu
              :model="menu"
              :layoutMode="layoutMode"
              :active="menuActive"
              :mobileMenuActive="staticMenuMobileActive"
              @menuitem-click="onMenuItemClick"
              @root-menuitem-click="onRootMenuItemClick"
            ></AppMenu>
          </div>
        </div>
      </transition>

      <div class="layout-main min-h-screen">
        <router-view />
        <div class="p-3"></div>
      </div>

      <div class="layout-mask"></div>

      <AppFooter />
    </div>
  </div>
</template>

<script>
import { usePrimeVue } from "primevue/config";
import { useToast } from "primevue/usetoast";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import AppFooter from "./AppFooter.vue";
import AppHeader from "./AppHeader.vue";
import AppInlineProfile from "./AppInlineProfile.vue";
import AppMenu from "./AppMenu.vue";
import AppTopBar from "./AppTopbar.vue";
import EventBus from "./event-bus";

export default {
  components: {
    AppHeader: AppHeader,
    AppTopBar: AppTopBar,
    AppInlineProfile: AppInlineProfile,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
  props: {
    theme: String,
    layoutColor: String,
    layoutScheme: String,
    darkMenu: Boolean,
  },
  setup(props, { emit }) {
    const layoutMode = ref("static");
    const profileMode = ref("top");
    const overlayMenuActive = ref(false);
    const staticMenuDesktopInactive = ref(false);
    const staticMenuMobileActive = ref(false);
    const topbarItemClick = ref(false);
    const topbarMenuActive = ref(false);
    const profileMenuActive = ref(false);
    const menuClick = ref(false);
    const activeTopbarItem = ref(null);
    const rotateMenuButton = ref(false);
    const menu = ref([
      {
        label: "title",
        icon: "pi pi-fw pi-home",
        items: [
          {
            label: "home",
            icon: "pi pi-fw pi-search",
            to: "/",
          },
          {
            label: "graveyards",
            icon: "pi pi-fw pi-list",
            to: "/graveyards",
          },
          {
            label: "create_grave",
            icon: "pi pi-fw pi-plus",
            to: "/create_grave",
          },
          {
            label: "import_graves",
            icon: "pi pi-fw pi-upload",
            to: "/import_graves",
          },
          {
            label: "create_graveyard",
            icon: "pi pi-fw pi-plus-circle",
            to: "/create_graveyard",
          },
        ],
      },
    ]);

    const menuActive = ref(false);
    const profileExpanded = ref(false);
    const themeColors = ref([
      { name: "Blue", file: "blue", color: "#0d6efd" },
      { name: "Indigo", file: "indigo", color: "#6610f2" },
      { name: "Purple", file: "purple", color: "#6f42c1" },
      { name: "Pink", file: "pink", color: "#d63384" },
      { name: "Orange", file: "orange", color: "#fd7e14" },
      { name: "Yellow", file: "yellow", color: "#ffc107" },
      { name: "Green", file: "green", color: "#198754" },
      { name: "Teal", file: "teal", color: "#20c997" },
      { name: "Cyan", file: "cyan", color: "#0dcaf0" },
    ]);
    const layoutColors = ref([
      { name: "Blue", file: "blue", color: "#0d6efd" },
      { name: "Indigo", file: "indigo", color: "#6610f2" },
      { name: "Purple", file: "purple", color: "#6f42c1" },
      { name: "Pink", file: "pink", color: "#d63384" },
      { name: "Orange", file: "orange", color: "#fd7e14" },
      { name: "Yellow", file: "yellow", color: "#ffc107" },
      { name: "Green", file: "green", color: "#198754" },
      { name: "Teal", file: "teal", color: "#20c997" },
      { name: "Cyan", file: "cyan", color: "#0dcaf0" },
    ]);

    const route = useRoute();
    const toast = useToast();
    const store = useStore();

    watch(
      () => route,
      () => {
        menuActive.value = false;
        toast.removeAllGroups();
      }
    );

    const onDocumentClick = () => {
      if (!topbarItemClick.value) {
        activeTopbarItem.value = null;
        topbarMenuActive.value = false;
      }

      if (!menuClick.value) {
        if (isHorizontal() || isSlim()) {
          menuActive.value = false;
          EventBus.emit("reset-active-index");
        }

        hideOverlayMenu();
      }

      topbarItemClick.value = false;
      menuClick.value = false;
    };

    const onProfileClick = (event) => {
      profileExpanded.value = !profileExpanded.value;
      if (isHorizontal() || isSlim()) {
        EventBus.emit("reset-active-index");
      }

      event.preventDefault();
    };

    const onMenuClick = () => {
      menuClick.value = true;
    };

    const onMenuItemClick = (event) => {
      if (!event.item.items) {
        EventBus.emit("reset-active-index");
        hideOverlayMenu();
      }
      if (!event.item.items && isHorizontal()) {
        menuActive.value = false;
      }
    };

    const onRootMenuItemClick = () => {
      menuActive.value = !menuActive.value;
    };

    const onMenuButtonClick = (event) => {
      menuClick.value = true;

      rotateMenuButton.value = !rotateMenuButton.value;
      topbarMenuActive.value = false;

      if (layoutMode.value === "overlay") {
        overlayMenuActive.value = !overlayMenuActive.value;
      } else {
        if (isDesktop()) {
          staticMenuDesktopInactive.value = !staticMenuDesktopInactive.value;
        } else {
          staticMenuMobileActive.value = !staticMenuMobileActive.value;
        }
      }

      event.preventDefault();
    };

    const onTopbarMenuButtonClick = (event) => {
      topbarItemClick.value = true;
      topbarMenuActive.value = !topbarMenuActive.value;
      hideOverlayMenu();
      event.preventDefault();
    };

    const onTopbarItemClick = (event) => {
      topbarItemClick.value = true;

      if (activeTopbarItem.value === event.item) {
        activeTopbarItem.value = null;
      } else {
        activeTopbarItem.value = event.item;
      }

      event.originalEvent.preventDefault();
    };

    const hideOverlayMenu = () => {
      rotateMenuButton.value = false;
      overlayMenuActive.value = false;
      staticMenuMobileActive.value = false;
    };

    const isDesktop = () => {
      return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
      return layoutMode.value === "horizontal";
    };

    const isSlim = () => {
      return layoutMode.value === "slim";
    };

    const isMenuVisible = () => {
      if (isDesktop()) {
        if (layoutMode.value === "static") {
          return !staticMenuDesktopInactive.value;
        } else if (layoutMode.value === "overlay") {
          return overlayMenuActive.value;
        } else {
          return true;
        }
      } else {
        if (staticMenuMobileActive.value) {
          return true;
        } else if (layoutMode.value === "overlay") {
          return overlayMenuActive.value;
        } else {
          return true;
        }
      }
    };

    const primevue = usePrimeVue();

    const containerClass = computed(() => {
      return [
        "layout-wrapper",
        {
          "menu-layout-static": layoutMode.value !== "overlay",
          "menu-layout-overlay": layoutMode.value === "overlay",
          "layout-menu-overlay-active": overlayMenuActive.value,
          "menu-layout-slim": layoutMode.value === "slim",
          "menu-layout-horizontal": layoutMode.value === "horizontal",
          "layout-menu-static-inactive": staticMenuDesktopInactive.value,
          "layout-menu-static-active": staticMenuMobileActive.value,
          "p-input-filled": primevue.config.inputStyle === "filled",
          "p-ripple-disabled": primevue.config.ripple === false,
        },
      ];
    });

    const menuClass = computed(() => {
      return [
        "layout-menu-container",
        { "layout-menu-dark": props.darkMenu.value },
      ];
    });

    return {
      layoutMode,
      profileMode,
      overlayMenuActive,
      staticMenuDesktopInactive,
      staticMenuMobileActive,
      topbarMenuActive,
      activeTopbarItem,
      rotateMenuButton,
      menu,
      menuActive,
      profileExpanded,
      themeColors,
      layoutColors,
      onDocumentClick,
      onProfileClick,
      onMenuClick,
      onMenuItemClick,
      onRootMenuItemClick,
      onMenuButtonClick,
      onTopbarMenuButtonClick,
      onTopbarItemClick,
      isMenuVisible,
      containerClass,
      menuClass,
    };
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
