import { auth } from "@/firebase";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../pages/Home.vue"),
    meta: {
      breadcrumb: [],
      authRequired: true,
    },
  },
  {
    path: "/graveyards/",
    name: "graveyards",
    component: () => import("../pages/Graveyards.vue"),
    meta: {
      breadcrumb: ["graveyards"],
      authRequired: true,
    },
  },
  {
    path: "/graveyards/:graveyardId",
    name: "graveyard",
    component: () => import("../pages/Graveyard.vue"),
    meta: {
      breadcrumb: ["graveyards", "graveyard"],
      authRequired: true,
    },
  },
  {
    path: "/create_graveyard",
    name: "create_graveyard",
    component: () => import("../pages/CreateGraveyard.vue"),
    meta: {
      breadcrumb: ["create_graveyard"],
      authRequired: true,
    },
  },
  {
    path: "/graveyards/:graveyardId/edit",
    name: "edit_graveyard",
    component: () => import("../pages/EditGraveyard.vue"),
    meta: {
      breadcrumb: ["graveyards", "graveyard", "edit"],
      authRequired: true,
    },
  },
  {
    path: "/grave/:graveId",
    name: "grave",
    component: () => import("../pages/Grave.vue"),
    meta: {
      breadcrumb: ["graveyards", "graveyard", "grave"],
      authRequired: true,
    },
  },
  {
    path: "/create_grave",
    name: "create_grave",
    component: () => import("../pages/CreateGrave.vue"),
    meta: {
      breadcrumb: ["create_grave"],
      authRequired: true,
    },
  },
  {
    path: "/import_graves",
    name: "import_graves",
    component: () => import("../pages/ImportGraves.vue"),
    meta: {
      breadcrumb: ["import_graves"],
      authRequired: true,
    },
  },
  {
    path: "/grave/:graveId/edit",
    name: "edit_grave",
    component: () => import("../pages/EditGrave.vue"),
    meta: {
      breadcrumb: ["graveyards", "graveyard", "grave", "edit"],
      authRequired: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeResolve(async (to, from, next) => {
  const currentUser = auth.currentUser;
  if (to.meta.authRequired && !currentUser) {
    return next("/login");
  } else {
    return next();
  }
});

export default router;
