import { db, functions } from "@/firebase";
import router from "@/router";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    graveyard: {},
    graveyards: [],
    uploadFiles: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createGraveyard({ commit }, data) {
    commit("wait");
    try {
      const createGraveyard = httpsCallable(functions, "createGraveyard");
      const res = await createGraveyard(data);
      if (res.data.error) {
        throw res.data.error;
      }
      commit("created", res.data.data);
      router.push("/graveyards");
    } catch (error) {
      commit("failure", error);
    }
  },
  async deleteGraveyard({ commit }, data) {
    commit("wait");
    try {
      const deleteGraveyard = httpsCallable(functions, "deleteGraveyard");
      const res = await deleteGraveyard({ id: data.id });
      if (res.data.error) {
        throw res.data.error.code;
      }
      commit("removeGraveyard", data);
      commit("success", "graveyard_deleted");
      router.push("/graveyards");
    } catch (error) {
      commit("failure", error);
    }
  },
  async editGraveyard({ commit }, data) {
    commit("wait");
    try {
      const editGraveyard = httpsCallable(functions, "editGraveyard");
      const res = await editGraveyard(data);
      if (res.data.error) {
        throw res.data.error.code;
      }
      commit("updated", res.data.data);
      router.push("/graveyards");
    } catch (error) {
      commit("failure", error);
    }
  },
  async retrieveGraveyard({ commit }, id) {
    commit("wait");
    try {
      const docRef = doc(db, "graveyards", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const graveyard = docSnap.data();
        graveyard.id = docSnap.id;
        commit("setGraveyard", graveyard);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async retrieveGraveyards({ commit }) {
    commit("wait");
    try {
      const q = query(
        collection(db, "graveyards"),
        orderBy("city"),
        orderBy("name")
      );
      const querySnapshot = await getDocs(q);
      const graveyards = querySnapshot.docs.map((doc) => {
        const graveyard = doc.data();
        graveyard.id = doc.id;
        return graveyard;
      });
      commit("setGraveyards", graveyards);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  created(state, graveyard) {
    state.graveyards.push(graveyard);
    state.status = {
      success: true,
      msg: "graveyard_created",
    };
    state.waiting = false;
  },
  failure(state, error) {
    state.status = {
      error: true,
      msg: error,
    };
    state.waiting = false;
  },
  removeGraveyard(state, payload) {
    const index = state.graveyards.findIndex(
      (graveyard) => graveyard.id == payload.id
    );

    if (index != -1) {
      state.graveyards.splice(index, 1);
    }
    state.graveyard = {};
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setGraveyard(state, graveyard) {
    state.graveyard = graveyard;
    state.waiting = false;
  },
  setGraveyards(state, graveyards) {
    state.graveyards = graveyards;
    state.waiting = false;
  },
  setUploadFiles(state, uploadFiles) {
    state.uploadFiles = uploadFiles;
    state.waiting = false;
  },
  success(state, msg) {
    if (msg) {
      state.status = {
        success: true,
        msg: msg,
      };
    }
    state.waiting = false;
  },
  updated(state, graveyard) {
    const index = state.graveyards.findIndex((g) => g.id == graveyard.id);
    if (index != -1) {
      state.graveyards.splice(index, 1, graveyard);
    }
    state.status = {
      success: true,
      msg: "graveyard_updated",
    };
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getGraveyard(state) {
    return state.graveyard;
  },
  getGraveyards(state) {
    return state.graveyards;
  },
  getUploadFiles(state) {
    return state.uploadFiles;
  },
};

const graveyard = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default graveyard;
