<template>
  <div
    class="layout-footer border-transparent bg-transparent"
    :style="{ marginTop: '-60px', height: '60px' }"
  >
    <span class="footer-text-left">
      <a
        href="https://www.kuuki.fi/"
        target="_blank"
        class="inline-flex gap-3 text-white align-items-center"
        >{{ t("text.partnership")
        }}<img alt="Kuuki Logo" src="/layout/images/logo.png"
      /></a>
    </span>
    <span class="footer-text-right">
      <form
        action="https://www.facebook.com/groups/473512779339429/"
        target="_blank"
      >
        <button class="p-link">
          <i class="pi pi-facebook"></i>
        </button>
      </form>
    </span>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "AppFooter",
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>
