import { db, functions } from "@/firebase";
import router from "@/router";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    status: {},
    grave: {},
    graves: [],
    persons: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createGrave({ commit }, data) {
    commit("wait");
    try {
      const createGrave = httpsCallable(functions, "createGrave");
      const res = await createGrave(data);
      if (res.data.error) {
        throw res.data.error;
      }
      commit("success", "grave_created");
      router.push(`/grave/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async deleteGrave({ commit }, data) {
    commit("wait");
    try {
      const deleteGrave = httpsCallable(functions, "deleteGrave");
      const res = await deleteGrave({ id: data.id });
      if (res.data.error) {
        throw res.data.error.code;
      }
      commit("removeGrave", data);
      commit("success", "grave_deleted");
      router.push(`/graveyards/${data.graveyardId}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async editGrave({ commit }, data) {
    commit("wait");
    try {
      const editGrave = httpsCallable(functions, "editGrave");
      const res = await editGrave(data);
      if (res.data.error) {
        throw res.data.error.code;
      }
      commit("success", "grave_updated");
      router.push(`/grave/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getGrave({ commit }, id) {
    commit("wait");
    try {
      const docRef = doc(db, "graves", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const grave = docSnap.data();
        grave.id = docSnap.id;
        commit("setGrave", grave);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getGraves({ commit }, id) {
    commit("wait");
    try {
      const q = query(collection(db, "graves"), where("graveyardId", "==", id));
      const querySnapshot = await getDocs(q);
      const graves = querySnapshot.docs.map((doc) => {
        const grave = doc.data();
        grave.id = doc.id;
        return grave;
      });

      commit("setGraves", graves);
    } catch (error) {
      commit("failure", error);
    }
  },
  async importGraves({ commit }, data) {
    commit("wait");
    try {
      const importGraves = httpsCallable(functions, "importGraves");
      const res = await importGraves(data);
      if (res.data.error) {
        throw res.data.error;
      }
      commit("success", "graves_imported");
    } catch (error) {
      commit("failure", error);
    }
  },
  async searchPersons({ commit }, searchParametres) {
    commit("wait");

    const getTrigraph = async (txt) => {
      const array = [];
      for (let i = txt.length; i >= 3; i--) {
        array.push(txt.substring(i - 3, i));
      }
      return array;
    };

    try {
      const queryConstraints = [];

      const firstNames = searchParametres.firstNames.toUpperCase();
      const lastname = searchParametres.lastname.toUpperCase();

      if (firstNames) {
        if (firstNames.length < 3) {
          queryConstraints.push(where("firstNames", "==", firstNames));
        } else {
          const firstTrigraph = await getTrigraph(firstNames);
          for (const tri of firstTrigraph) {
            queryConstraints.push(where(`firstTrigraph.${tri}`, "==", true));
          }
        }
      }

      if (lastname.length < 3) {
        queryConstraints.push(where("lastname", "==", lastname));
      } else {
        const lastTrigraph = await getTrigraph(lastname);

        for (const tri of lastTrigraph) {
          queryConstraints.push(where(`lastTrigraph.${tri}`, "==", true));
        }
      }
      if (searchParametres.graveyardId) {
        queryConstraints.push(
          where("graveyardId", "==", searchParametres.graveyardId)
        );
      }
      const q = query(collection(db, "persons"), ...queryConstraints);
      const querySnapshot = await getDocs(q);
      const persons = querySnapshot.docs.map((doc) => {
        const person = doc.data();
        person.id = doc.id;
        return person;
      });
      commit("setPersons", persons);
    } catch (error) {
      commit("failure", error);
    }
  },
  async uploadImages({ commit }, uploads) {
    commit("wait");
    try {
      const uploadImages = httpsCallable(functions, "uploadImages");
      const res = await uploadImages({ uploads });
      if (res.data.error) {
        throw res.data.error;
      }
      commit("success", "images_uploaded");
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status = {
      error: true,
      msg: error,
    };
    state.graves = [];
    state.persons = [];
    state.waiting = false;
    if (error.badRow) {
      router.push("/");
      setTimeout(() => router.push("/import_graves"), 200);
    }
  },
  removeGrave(state, payload) {
    const index = state.graves.findIndex((grave) => grave.id == payload.id);

    if (index != -1) {
      state.graves.splice(index, 1);
    }
    state.grave = {};
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setGrave(state, payload) {
    state.grave = payload;
    state.persons = [];
    state.waiting = false;
  },
  setGraves(state, payload) {
    state.graves = payload;
    state.persons = [];
    state.waiting = false;
  },
  setPersons(state, payload) {
    state.persons = payload;
    state.waiting = false;
  },
  sortGraves(state, sortOrder) {
    state.graves.sort((a, b) => {
      if (
        a.persons &&
        b.persons &&
        a.persons[0].lastname > b.persons[0].lastname
      ) {
        return sortOrder * 1;
      } else if (
        a.persons &&
        b.persons &&
        a.persons[0].lastname < b.persons[0].lastname
      ) {
        return sortOrder * -1;
      } else {
        return 0;
      }
    });
  },
  success(state, msg) {
    if (msg) {
      state.status = {
        success: true,
        msg: msg,
      };
    }
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
    state.graves = [{}, {}, {}, {}, {}, {}];
    state.persons = [{}, {}, {}, {}, {}, {}];
  },
};

const getters = {
  getGrave(state) {
    return state.grave;
  },
  getGraves(state) {
    return state.graves;
  },
  getPersons(state) {
    return state.persons;
  },
};

const grave = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default grave;
