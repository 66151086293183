<template>
  <login v-if="$route.path === '/login'" />
  <app
    v-else
    :theme="theme"
    :layoutScheme="layoutScheme"
    :layoutColor="layoutColor"
    :darkMenu="darkMenu"
    @menu-color-change="menuColorChange"
    @theme-change="changeTheme"
    @layout-change="changeLayout"
    @layout-scheme-change="changeLayoutScheme"
  />
  <ConfirmDialog class="md:w-4 lg:w-3" />
  <Toast />
</template>

<script>
import { useToast } from "primevue/usetoast";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import App from "./App.vue";
import Login from "./pages/Login";

export default {
  components: {
    App: App,
    Login: Login,
  },
  setup() {
    const theme = ref("purple");
    const layoutScheme = ref("light");
    const layoutColor = ref("purple");
    const darkMenu = ref(false);

    const menuColorChange = (menuColor) => {
      darkMenu.value = menuColor;
    };

    const changeLayoutScheme = (scheme) => {
      layoutScheme.value = scheme;
      darkMenu.value = scheme === "dark";

      const themeLink = document.getElementById("theme-css");
      const urlTokens = themeLink.getAttribute("href").split("/");
      urlTokens[urlTokens.length - 1] = "theme-" + layoutScheme.value + ".css";
      const newURL = urlTokens.join("/");

      replaceLink(themeLink, newURL);
    };

    const changeTheme = (nextTheme) => {
      theme.value = nextTheme;

      const themeLink = document.getElementById("theme-css");
      const themeHref =
        "/theme/" + theme.value + "/theme-" + layoutScheme.value + ".css";
      replaceLink(themeLink, themeHref);
    };

    const changeLayout = (layout) => {
      layoutColor.value = layout;

      const layoutLink = document.getElementById("layout-css");
      const layoutHref = "/layout/css/layout-" + layout + ".css";
      replaceLink(layoutLink, layoutHref);
    };

    const replaceLink = (linkElement, href) => {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", id);
      });
    };

    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();

    watch(
      () => store.state.user.status,
      (status) => {
        if (status.success) {
          toast.add({
            severity: "success",
            summary: t("toast_headers.success"),
            detail: t("successes." + status.msg),
          });
        }
        if (status.error) {
          toast.add({
            severity: "error",
            summary: t("toast_headers.error"),
            detail: t("errors." + status.msg),
          });
        }
      }
    );

    watch(
      () => store.state.grave.status,
      (status) => {
        if (status.success) {
          toast.add({
            severity: "success",
            summary: t("toast_headers.success"),
            detail: t("successes." + status.msg),
          });
        }
        if (status.error) {
          if (status.msg.lastRow) {
            toast.add({
              severity: "error",
              summary: t("toast_headers.error"),
              detail: t("errors.last_row", {
                lastname: status.msg.lastRow.lastname,
                firstNames: status.msg.lastRow.firstNames,
                images: status.msg.lastRow.images,
              }),
            });
          } else if (status.msg.badRow) {
            toast.add({
              severity: "error",
              summary: t("toast_headers.error"),
              detail: t("errors.bad_row", {
                lastname: status.msg.badRow.lastname,
                firstNames: status.msg.badRow.firstNames,
                images: status.msg.badRow.images,
              }),
            });
          } else {
            toast.add({
              severity: "error",
              summary: t("toast_headers.error"),
              detail: t("errors." + status.msg),
            });
          }
        }
      }
    );

    watch(
      () => store.state.graveyard.status,
      (status) => {
        if (status.success) {
          toast.add({
            severity: "success",
            summary: t("toast_headers.success"),
            detail: t("successes." + status.msg),
          });
        }
        if (status.error) {
          toast.add({
            severity: "error",
            summary: t("toast_headers.error"),
            detail: t("errors." + status.msg),
          });
        }
      }
    );

    return {
      theme,
      layoutScheme,
      layoutColor,
      darkMenu,
      menuColorChange,
      changeLayoutScheme,
      changeTheme,
      changeLayout,
    };
  },
};
</script>
