<template>
  <div
    class="bg-primary h-full p-5"
    :style="{
      backgroundImage: 'url(/layout/images/bg.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }"
  >
    <div class="grid h-full">
      <div class="col-4 hidden sm:block" />
      <div class="col flex align-items-center justify-content-center">
        <div class="card grid">
          <div class="col-1 hidden lg:block" />
          <div class="col-12 flex align-items-center justify-content-center">
            <login-form @forgot="showForgotModal" />
          </div>
          <div class="col-1 hidden lg:block" />
        </div>
      </div>
      <div class="col-4 hidden sm:block" />
    </div>
    <Dialog
      class="sm:w-6 lg:w-4"
      :modal="true"
      :header="t('dialog_headers.forgotten_password')"
      v-model:visible="displayForgotModal"
    >
      <p>{{ t("dialog_headers.forgotten_password_subheader") }}</p>
      <email-form />
    </Dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import EmailForm from "@/components/forms/EmailForm.vue";
import LoginForm from "@/components/forms/LoginForm.vue";

export default {
  components: { LoginForm, EmailForm },
  setup() {
    const displayForgotModal = ref(false);

    const showForgotModal = () => {
      displayForgotModal.value = true;
    };

    const { t } = useI18n();

    return {
      displayForgotModal,
      showForgotModal,
      t,
    };
  },
};
</script>
