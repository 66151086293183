import { createStore } from "vuex";

import user from "./modules/user.store";
import grave from "./modules/grave.store";
import graveyard from "./modules/graveyard.store";

const storeOptions = {
  actions: {
    clearAll({ commit }) {
      commit("grave/resetState");
      commit("graveyard/resetState");
      commit("user/resetState");
    },
  },
  modules: {
    user,
    grave,
    graveyard,
  },
};

export default createStore(storeOptions);
